import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { ContextType, PropsWithChildren } from 'react';

import {
  MODAL_ID_CANCEL_OPTION,
  MODAL_ID_CANCEL_PREBOOK,
  MODAL_ID_CREATE_OPTION,
} from 'settings/modal';

import type {
  ModalDataActivity,
  ModalDataLotProgram,
  ModalDataType,
} from 'modules/ActionsProgramsAndLots/Modal';

import activityModalsContext from 'modules/App/Contexts/activityModalsContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';

import ModalOption from 'modules/ActionsProgramsAndLots/ModalOption/ModalOption';
import ModalCancelOption from 'modules/ActionsProgramsAndLots/ModalOption/ModalCancelOption';
import ModalCancelPreBook from 'modules/ActionsProgramsAndLots/ModalPreBook/ModalCancelPreBook';

function modalDataIsCreateOption(
  modalData: ModalDataLotProgram | ModalDataActivity | undefined
): modalData is ModalDataLotProgram {
  const data = modalData as ModalDataLotProgram | undefined;
  return (
    data?.number !== undefined &&
    data?.program?.ref !== undefined &&
    data.program?.name !== undefined
  );
}
function modalDataIsCancel(
  modalData: ModalDataLotProgram | ModalDataActivity | undefined
): modalData is ModalDataActivity {
  const data = modalData as ModalDataActivity | undefined;
  return data?.activityId !== undefined && data?.lotNumber !== undefined;
}

export default function ActivityModalsProvider({
  children,
}: PropsWithChildren<Record<never, never>>) {
  const { settings } = useContext(SettingsContext);
  const [currentModal, setCurrentModal] = useState<
    ContextType<typeof activityModalsContext>['currentModal']
  >();
  const modalDataRef = useRef<ModalDataType>();

  const { data: modalData, onSuccess, optimisticSuccess, undoOptimisticSuccess } =
    modalDataRef.current ?? {};

  const openCreateOption = useMemo<
    ContextType<typeof activityModalsContext>['openCreateOption']
  >(() => {
    if (settings.commercial_readonly) {
      return () => {};
    }
    return (
      { lotNumber, programRef, programName },
      onSuccess,
      optimisticSuccess,
      undoOptimisticSuccess
    ) => {
      setCurrentModal(MODAL_ID_CREATE_OPTION);
      modalDataRef.current = {
        data: {
          number: lotNumber,
          program: {
            ref: programRef,
            name: programName,
          },
        },
        onSuccess,
        optimisticSuccess,
        undoOptimisticSuccess,
      };
    };
  }, [settings.commercial_readonly]);

  const openCancelOption = useMemo<
    ContextType<typeof activityModalsContext>['openCancelOption']
  >(() => {
    if (settings.commercial_readonly) {
      return () => {};
    }
    return (
      { activityId, expirationDate, lotNumber },
      onSuccess,
      optimisticSuccess,
      undoOptimisticSuccess
    ) => {
      setCurrentModal(MODAL_ID_CANCEL_OPTION);
      modalDataRef.current = {
        data: { activityId, expirationDate, lotNumber },
        onSuccess,
        optimisticSuccess,
        undoOptimisticSuccess,
      };
    };
  }, [settings.commercial_readonly]);

  const openCancelPreBooking = useMemo<
    ContextType<typeof activityModalsContext>['openCancelPreBooking']
  >(() => {
    if (settings.commercial_readonly) {
      return () => {};
    }
    return (
      { activityId, expirationDate, lotNumber },
      onSuccess,
      optimisticSuccess,
      undoOptimisticSuccess
    ) => {
      setCurrentModal(MODAL_ID_CANCEL_PREBOOK);
      modalDataRef.current = {
        data: { activityId, expirationDate, lotNumber },
        onSuccess,
        optimisticSuccess,
        undoOptimisticSuccess,
      };
    };
  }, [settings.commercial_readonly]);

  const closeModal = useCallback(() => {
    setCurrentModal(undefined);
  }, []);

  return (
    <activityModalsContext.Provider
      value={{
        currentModal,
        closeModal,
        openCreateOption,
        openCancelOption,
        openCancelPreBooking,
      }}
    >
      {children}
      {!settings.commercial_readonly && (
        <>
          <ModalOption
            open={currentModal === MODAL_ID_CREATE_OPTION && modalDataIsCreateOption(modalData)}
            lotNumber={modalDataIsCreateOption(modalData) ? modalData.number : undefined}
            programRef={modalDataIsCreateOption(modalData) ? modalData.program.ref : undefined}
            programName={modalDataIsCreateOption(modalData) ? modalData.program.name : undefined}
            closeCallBack={closeModal}
            onClick={ev => ev.stopPropagation()}
            onSuccess={onSuccess}
            optimisticSuccess={optimisticSuccess}
            undoOptimisticSuccess={undoOptimisticSuccess}
          />
          <ModalCancelOption
            open={currentModal === MODAL_ID_CANCEL_OPTION && modalDataIsCancel(modalData)}
            activityId={modalDataIsCancel(modalData) ? modalData.activityId : undefined}
            expirationDate={modalDataIsCancel(modalData) ? modalData.expirationDate : undefined}
            lotNumber={modalDataIsCancel(modalData) ? modalData.lotNumber : undefined}
            onClose={closeModal}
            onSuccess={onSuccess}
            optimisticSuccess={optimisticSuccess}
            undoOptimisticSuccess={undoOptimisticSuccess}
          />
          <ModalCancelPreBook
            open={currentModal === MODAL_ID_CANCEL_PREBOOK && modalDataIsCancel(modalData)}
            activityId={modalDataIsCancel(modalData) ? modalData.activityId : undefined}
            expirationDate={modalDataIsCancel(modalData) ? modalData.expirationDate : undefined}
            lotNumber={modalDataIsCancel(modalData) ? modalData.lotNumber : undefined}
            onClose={closeModal}
            onSuccess={onSuccess}
            optimisticSuccess={optimisticSuccess}
            undoOptimisticSuccess={undoOptimisticSuccess}
          />
        </>
      )}
    </activityModalsContext.Provider>
  );
}

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import { getActivityActions } from 'services/activities';
import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
} from 'settings/activity';
import {
  LABEL_GENERATE_RESERVATION_CONTRACT,
  LABEL_LOAD_PROBLEM_ERROR_SUBTITLE,
  LABEL_LOAD_PROBLEM_ERROR_TITLE,
  LABEL_LOTS_ENABLE_ERROR_SUBTITLE,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_MODAL_MISSING_DOCUMENTS_ADDITIONAL_TITLE,
  LABEL_MODAL_MISSING_DOCUMENTS_TITLE,
  LABEL_MODIFY_RESERVATION_CONTRACT,
  LABEL_OPTIONATE,
  LABEL_PRE_BOOK,
  LABEL_SEND_SIGNATURE_REQUEST_CLIENT,
} from 'settings/labels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import programLotContext from 'modules/App/Contexts/programLotContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { useActivityChoices } from 'hooks/useActivities';

import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import ActivityDownloadContract from './ActivityDownloadContract';

import ActivityInfo from '../ActivityInfo/ActivityInfo';

import styles from './ActivityActions.module.scss';

interface ActivityActionsProps {
  activity: Activity;
  apiUrls: {
    bookings: string | undefined;
    options: string | undefined;
    preBookings: string | undefined;
    sales: string | undefined;
  };
  className?: string;
}

export default function ActivityActions({
  activity,
  apiUrls,
  className = undefined,
}: ActivityActionsProps) {
  const { settings } = useContext(SettingsContext);
  const { isResponsive } = useContext(ResponsiveContext);
  const { openCreateOption, openCancelOption, openCancelPreBooking } = useContext(
    ActivityModalsContext
  );
  const [openError, setOpenError] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [time, setTime] = useState<number>();
  const [getDocuments, setGetDocuments] = useState(false);
  const history = useHistory();

  const { choices, ...swrChoices } = useActivityChoices(
    getDocuments ? { preBookingId: activity.field_idcrm_ac, time } : { preBookingId: undefined },
    {
      shouldRetryOnError: false,
    }
  );
  const undoMutate = useRef<Record<string, any>>({});

  useEffect(() => {
    if (choices?.documents) {
      const hasMandatoryDocuments =
        choices?.documents?.conditionsParticulieres.length &&
        choices?.documents?.noticesDescriptives.length;
      setGetDocuments(false);
      if (!hasMandatoryDocuments) {
        setOpenError(true);
        window.scrollTo(0, 0);
      } else {
        history.push(`/generation-contrat/${activity.field_idcrm_ac}`);
      }
    }
  }, [getDocuments, choices]);

  useEffect(() => {
    if (swrChoices?.error) {
      setLoadError(true);
    }
  }, [swrChoices?.error]);

  const { programs } = useContext(programLotContext);
  const eligibility = useMemo(() => {
    if (!programs?.length) {
      return false;
    }
    return !!programs.find(p => p.ref === activity.field_referenceprogramme_ac)?.eligibility;
  }, [programs]);
  const {
    showCancelOption,
    showCancelPreBook,
    showDownloadContract,
    showEditContract,
    showInfo,
    showMakeContract,
    showMakeOption,
    showMakePrebook,
    showSendSignRequest,
    isSignRequestReadonly,
  } = getActivityActions(activity, eligibility, settings.commercial_readonly);

  if (
    isResponsive &&
    [
      showCancelOption,
      showCancelPreBook,
      showDownloadContract,
      showEditContract,
      showMakeContract,
      showMakeOption,
      showMakePrebook,
      showSendSignRequest,
      isSignRequestReadonly,
    ].every(x => !x)
  ) {
    return null;
  }

  function createOptimisticSuccess(apiKey: keyof typeof apiUrls, undoKey: string) {
    return () =>
      mutate(
        apiUrls[apiKey],
        (activities: Activity[]) => {
          const index = activities.findIndex(
            ({ field_idcrm_ac: id }) => id === activity.field_idcrm_ac
          );
          undoMutate.current[undoKey] = { index, activity: activities[index] };
          const newActivities = [...activities];
          newActivities.splice(index, 1, {
            ...activities[index],
            field_statutnom_ac: ACTIVITY_STATUS_ACTIVE,
          });
          return newActivities;
        },
        false
      );
  }
  function undoCreateOptimisticSuccess(apiKey: keyof typeof apiUrls, undoKey: string) {
    return () =>
      mutate(
        apiUrls[apiKey],
        (activities: Activity[]) =>
          undoMutate.current[undoKey]
            ? [
                ...activities.slice(0, undoMutate.current[undoKey].index),
                undoMutate.current[undoKey].activity,
                ...activities.slice(undoMutate.current[undoKey].index + 1),
              ]
            : activities,
        false
      );
  }
  function cancelOptimisticSuccess(apiKey: keyof typeof apiUrls, undoKey: string) {
    return () =>
      mutate(
        apiUrls[apiKey],
        (data: Activity[]) => {
          undoMutate.current[undoKey] = data;
          return data.map(d =>
            d.field_idcrm_ac === activity.field_idcrm_ac
              ? { ...d, field_statutnom_ac: ACTIVITY_STATUS_CANCELED }
              : d
          );
        },
        false
      );
  }
  function undoCancelOptimisticSuccess(apiKey: keyof typeof apiUrls, undoKey: string) {
    return () =>
      mutate(
        apiUrls[apiKey],
        (data: Activity[]) => {
          let res: any;
          if (undoMutate.current[undoKey]) {
            res = undoMutate.current[undoKey];
            undoMutate.current[undoKey] = null;
          }
          return (
            res ||
            data.map(d =>
              d.field_idcrm_ac === activity.field_idcrm_ac && undoMutate.current[undoKey]
                ? { ...d, field_statutnom_ac: undoMutate.current[undoKey] }
                : d
            )
          );
        },
        false
      );
  }
  return (
    <div className={classnames(className, styles.list)}>
      {(showMakeContract || showEditContract) && (
        <div className={styles.item}>
          {!getDocuments && (
            <Tooltip
              arrow
              title={
                showEditContract
                  ? LABEL_MODIFY_RESERVATION_CONTRACT
                  : LABEL_GENERATE_RESERVATION_CONTRACT
              }
              placement="top"
            >
              <button
                className={styles.button}
                onClick={ev => {
                  setTime(Date.now());
                  ev.stopPropagation();
                  setGetDocuments(true);
                }}
                type="button"
              >
                <SvgIcon className={styles.icon} iconId="icon-generate-contract" />
              </button>
            </Tooltip>
          )}

          {getDocuments && !loadError && <CircularProgress variant="indeterminate" size={24} />}
        </div>
      )}
      {showDownloadContract && (
        <div className={styles.item}>
          <ActivityDownloadContract
            activity={activity}
            classes={{
              button: styles.button,
              buttonIcon: styles.icon,
            }}
          />
        </div>
      )}
      {showSendSignRequest && (
        <div className={styles.item}>
          <Tooltip arrow title={LABEL_SEND_SIGNATURE_REQUEST_CLIENT} placement="top">
            <ReactRouterLink
              className={styles.button}
              to={
                isSignRequestReadonly
                  ? `/signature-contrat/${activity.field_idcrm_ac}?readonly`
                  : `/signature-contrat/${activity.field_idcrm_ac}`
              }
              onClick={ev => ev.stopPropagation()}
            >
              <SvgIcon className={styles.icon} iconId="icon-send-documents" />
            </ReactRouterLink>
          </Tooltip>
        </div>
      )}
      {showMakeOption && activity.field_type_ac === ACTIVITY_TYPE_OPTION && (
        <div className={styles.item}>
          <Tooltip arrow title={LABEL_OPTIONATE} placement="top">
            <button
              type="button"
              className={styles.button}
              onClick={ev => {
                ev.stopPropagation();
                openCreateOption(
                  {
                    lotNumber: activity.field_numerolot_ac,
                    programRef: activity.field_referenceprogramme_ac,
                    programName: activity.field_nomprogramme_ac,
                  },
                  () => {
                    undoMutate.current.createOption = null;
                  },
                  createOptimisticSuccess('options', 'createOption'),
                  undoCreateOptimisticSuccess('options', 'createOption')
                );
              }}
            >
              <SvgIcon className={styles.icon} iconId="icon-option" />
            </button>
          </Tooltip>
        </div>
      )}
      {showMakePrebook && (
        <div className={styles.item}>
          <Tooltip arrow title={LABEL_PRE_BOOK} placement="top">
            <ReactRouterLink
              to={`/pre-reservation/${activity.field_referenceprogramme_ac}/${activity.field_numerolot_ac}`}
              className={styles.button}
              onClick={ev => ev.stopPropagation()}
            >
              <SvgIcon className={styles.icon} iconId="icon-pre-book" />
            </ReactRouterLink>
          </Tooltip>
        </div>
      )}
      {showInfo && (
        <ActivityInfo className={styles.item} btnClassName={styles.button} activity={activity} />
      )}
      {activity.field_type_ac === ACTIVITY_TYPE_OPTION && showCancelOption && (
        <div className={styles.item}>
          <Tooltip arrow title={LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION} placement="top">
            <button
              type="button"
              data-test-id="activity-row--cancel-button"
              className={styles.button}
              onClick={ev => {
                ev.stopPropagation();
                openCancelOption(
                  {
                    activityId: activity.field_idcrm_ac,
                    expirationDate: activity.field_dateexpiration_ac
                      ? new Date(activity.field_dateexpiration_ac)
                      : undefined,
                    lotNumber: activity.field_numerolot_ac,
                  },
                  () => {
                    undoMutate.current.cancelOption = null;
                  },
                  cancelOptimisticSuccess('options', 'cancelOption'),
                  undoCancelOptimisticSuccess('options', 'cancelOption')
                );
              }}
            >
              <SvgIcon className={styles.icon} iconId="icon-cancel" />
            </button>
          </Tooltip>
        </div>
      )}
      {activity.field_type_ac === ACTIVITY_TYPE_PRE_BOOKING && showCancelPreBook && (
        <div className={styles.item}>
          <Tooltip arrow title={LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK} placement="top">
            <button
              type="button"
              className={styles.button}
              onClick={ev => {
                ev.stopPropagation();
                openCancelPreBooking(
                  {
                    activityId: activity.field_idcrm_ac,
                    expirationDate: activity.field_dateexpiration_ac
                      ? new Date(activity.field_dateexpiration_ac)
                      : undefined,
                    lotNumber: activity.field_numerolot_ac,
                  },
                  () => {
                    undoMutate.current.cancelPreBook = null;
                  },
                  cancelOptimisticSuccess('preBookings', 'cancelPreBook'),
                  undoCancelOptimisticSuccess('preBookings', 'cancelPreBook')
                );
              }}
            >
              <SvgIcon className={styles.icon} iconId="icon-cancel" />
            </button>
          </Tooltip>
        </div>
      )}
      {openError && (
        <LotTableErrorModal
          open
          additionalTitle={LABEL_MODAL_MISSING_DOCUMENTS_ADDITIONAL_TITLE}
          onClose={ev => {
            ev.stopPropagation();
            setOpenError(false);
          }}
          paperClassName={styles.modalPaper}
          scrollPaperClassName={styles.modalScrollPaper}
          title={LABEL_MODAL_MISSING_DOCUMENTS_TITLE}
          subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
          disablePortal={false}
        />
      )}
      {loadError && (
        <LotTableErrorModal
          open
          onClose={ev => {
            ev.stopPropagation();
            setLoadError(false);
            window.location.reload();
          }}
          paperClassName={styles.modalPaper}
          withCloseBtn
          scrollPaperClassName={styles.modalScrollPaper}
          title={LABEL_LOAD_PROBLEM_ERROR_TITLE}
          subtitle={LABEL_LOAD_PROBLEM_ERROR_SUBTITLE}
          disablePortal={false}
        />
      )}
    </div>
  );
}

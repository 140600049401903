import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_ADD_TO_A_FOLDER,
  LABEL_BOOKLET,
  LABEL_CARD_LOT,
  LABEL_CARD_PROGRAM,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_OPTIONATE,
  LABEL_PRE_BOOK,
} from 'settings/labels';
import { LOT_STATUS_FREE, LOT_STATUS_OPTIONED } from 'settings/lots';
import {
  MODAL_ID_SEND_BOOKLET,
  MODAL_ID_SEND_CARD_LOT,
  MODAL_ID_SEND_CARD_PROGRAM,
} from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { LotJson, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { Status, StatusLabel } from 'api/viOffresAPI/apiTypes/Statuses';

import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import programPdfContext from 'modules/App/Contexts/programPdfContext';
import lotPdfContext from 'modules/App/Contexts/lotPdfContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { getLotActionButtons } from 'services/lots';

import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';

import Button from 'commonUi/Button/Button';
import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import PdfButtonUI from 'commonUi/Dropdowns/PdfButtonUI';

import BookletDropdown from 'commonUi/Dropdowns/BookletDropdown';

import styles from './LotButtons.module.scss';

const lotDropdown = {
  label: LABEL_CARD_LOT,
  icon: 'lot-card' as const,
  id: 'lot-card',
  modalId: MODAL_ID_SEND_CARD_LOT,
};
const programDropdown = {
  label: LABEL_CARD_PROGRAM,
  icon: 'program-card' as const,
  id: 'program-card',
  modalId: MODAL_ID_SEND_CARD_PROGRAM,
};

interface LotButtonsProps {
  isOdd?: boolean;
  lot: Pick<LotJson, 'nid' | 'number' | 'ref' | 'status'> & {
    program: Pick<LotJson['program'], 'name'>;
  };
  lotFromApi: LotTypeV2;
  myOptions?: Activity[];
  myBookings?: Activity[];
  openDropdownTop?: boolean;
  pageTemplate?: string;
  pageTemplateRef?: string;
  program: ProgramTypeV2;
  statuses: Record<number, Status>;
  updateStatus?: (lotNid: string, status: StatusLabel) => void;
  openPanel: (panel: string) => void;
}

export function LotButtons({
  isOdd = false,
  lot,
  lotFromApi,
  myOptions,
  myBookings,
  openDropdownTop = false,
  pageTemplate,
  pageTemplateRef,
  program,
  statuses,
  updateStatus,
  openPanel,
}: LotButtonsProps) {
  const { settings } = useContext(SettingsContext);
  const { openCancelOption, openCancelPreBooking, openCreateOption } = useContext(
    ActivityModalsContext
  );
  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(program?.referenceProgramme, true);

  const option = myOptions?.find(
    option =>
      option.field_numerolot_ac === lot.number && option.field_referenceprogramme_ac === lot.ref
  );
  const preBooking = myBookings?.find(
    booking =>
      booking.field_numerolot_ac === lot.number && booking.field_referenceprogramme_ac === lot.ref
  );

  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType, setFolderTms } = useContext(
    foldersContext
  );
  const { setPageVars, setComponentPageVars } = useContext(TmsContext);

  function handleAddFolderClick() {
    if (folderSaveType !== 'lot') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('lot');
    setFolderLots([{ lotNumber: lot.number, programRef: lot.ref }]);
    if (pageTemplate && pageTemplateRef) {
      setFolderTms({
        confirmation: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}.${lot.number}_ajoutdossier.confirmation`,
        },
        default: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}.${lot.number}_ajoutdossier`,
        },
      });
    }
  }

  const {
    showCancelOption,
    showCancelPreBook,
    showMakeOption,
    showMakePrebook,
  } = getLotActionButtons(
    lot ? statuses?.[lot.nid]?.label ?? lot.status : undefined,
    Boolean(option) || Boolean(preBooking),
    settings.commercial_readonly
  );

  const {
    getPdf: getPdfProgram,
    programs: programPdfProgram,
    generate: generateProgram,
    isGenerating: isGeneratingProgram,
  } = useContext(programPdfContext);
  const pdfProgram = getPdfProgram(lot.ref);

  const {
    getPdf: getPdfLot,
    lotsDetail: lotsPdfLot,
    programs: lotPdfProgram,
    generate: generateLot,
    isGenerating: isGeneratingLot,
  } = useContext(lotPdfContext);
  const pdfLot = getPdfLot(lot.nid);

  const [activeButton, setActiveButton] = useState<string>();

  const lotDropdownTms =
    pageTemplate && pageTemplateRef
      ? {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_fichelot`,
        }
      : undefined;

  const programDropdownTms =
    pageTemplate && pageTemplateRef
      ? {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_ficheprogramme`,
        }
      : undefined;

  return (
    <div className={classnames(styles.buttons, { [styles.odd]: isOdd })}>
      <div className={styles.buttonsGroup}>
        {showMakeOption && (
          <div className={styles.buttonWrapper}>
            <Button
              data-test-id="lot-buttons--optionate"
              className={styles.button}
              color="primary"
              iconId="icon-option"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() =>
                openCreateOption(
                  {
                    lotNumber: lot.number,
                    programRef: lot.ref,
                    programName: lot.program.name,
                  },
                  () => {
                    if (typeof updateStatus === 'function') {
                      updateStatus(lot.nid, LOT_STATUS_OPTIONED);
                    }
                  }
                )
              }
            >
              {LABEL_OPTIONATE}
            </Button>
          </div>
        )}
        {showCancelOption && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-cancel"
              data-test-id="lot-buttons--cancel-optionate"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() => {
                if (option?.field_dateexpiration_ac) {
                  openCancelOption(
                    {
                      activityId: option.field_idcrm_ac,
                      expirationDate: new Date(option.field_dateexpiration_ac),
                      lotNumber: option.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            >
              {LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION}
            </Button>
          </div>
        )}
        {showMakePrebook && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              data-test-id="lot-buttons--pre-book"
              labelClassName={styles.buttonLabel}
              variant="outlined"
              component={RouterLink}
              to={`/pre-reservation/${lot.ref}/${lot.number}?backUrl=${encodeURIComponent(
                window.location.href
              )}`}
              onClick={() => {
                setPageVars({});
                setComponentPageVars({});
              }}
            >
              {LABEL_PRE_BOOK}
            </Button>
          </div>
        )}
        {showCancelPreBook && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() => {
                if (preBooking?.field_dateexpiration_ac) {
                  openCancelPreBooking(
                    {
                      activityId: preBooking.field_idcrm_ac,
                      expirationDate: new Date(preBooking.field_dateexpiration_ac),
                      lotNumber: preBooking.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            >
              {LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK}
            </Button>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            color="primary"
            iconId="icon-add-to-folder"
            iconClass={styles.buttonSvgIcon}
            labelClassName={styles.buttonLabel}
            onClick={handleAddFolderClick}
            variant="outlined"
          >
            {LABEL_ADD_TO_A_FOLDER}
          </Button>
        </div>
      </div>

      <div className={styles.buttonsGroup}>
        <div className={styles.buttonWrapper}>
          <BookletDropdown
            bookletUrl={booklet?.url}
            buttonIcon="booklet"
            buttonId="booklet"
            lot={lotFromApi}
            modalId={MODAL_ID_SEND_BOOKLET}
            openModal={openModal}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
          >
            {LABEL_BOOKLET}
          </BookletDropdown>
        </div>
        <div className={styles.buttonWrapper}>
          <ButtonModalPdf
            pdf={pdfProgram}
            program={programPdfProgram[lot.ref]}
            open={open === MODAL_ID_SEND_CARD_PROGRAM}
            onClose={() => closeModal()}
          >
            <PdfButtonUI
              onClick={() => {
                if (!pdfProgram) {
                  generateProgram(lot.ref);
                }
              }}
              loading={isGeneratingProgram[lot.ref]}
              button={programDropdown}
              isButtonActive={activeButton === programDropdown.id}
              url={pdfProgram?.url}
              setActiveButton={setActiveButton}
              openDropdownTop={openDropdownTop}
              openModal={openModal}
              tms={programDropdownTms}
            />
          </ButtonModalPdf>
        </div>
        <div className={styles.buttonWrapper}>
          <ButtonModalPdf
            pdf={pdfLot}
            program={lotPdfProgram[lot.ref]}
            lot={lotsPdfLot[lot.nid]}
            open={open === MODAL_ID_SEND_CARD_LOT}
            onClose={() => closeModal()}
          >
            <PdfButtonUI
              onClick={() => {
                if (!pdfLot) {
                  generateLot(lot.ref, lot.number, lot.nid);
                }
              }}
              loading={isGeneratingLot[lot.nid]}
              button={lotDropdown}
              isButtonActive={activeButton === lotDropdown.id}
              url={pdfLot?.url}
              setActiveButton={setActiveButton}
              openDropdownTop={openDropdownTop}
              openModal={openModal}
              tms={lotDropdownTms}
            />
          </ButtonModalPdf>
        </div>
      </div>

      {open === MODAL_ID_SEND_BOOKLET && (
        <ModalSendBooklet
          booklet={booklet}
          closeCallBack={closeModal}
          open={open === MODAL_ID_SEND_BOOKLET}
          program={program}
        />
      )}
    </div>
  );
}

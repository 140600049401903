import React from 'react';

import { TAX_TYPE_LMNP } from 'settings/taxes';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { LotCard } from 'commonUi/LotCard/LotCard';

interface LotCardCpnProps {
  programRef: string;
  lotNumber: string;
}

export default function LotCardLoadProgramAndLot({ programRef, lotNumber }: LotCardCpnProps) {
  const { lot, program, isLoading } = useLoadProgramAndLot(programRef, lotNumber);

  return (
    <LotCard lot={lot} program={program} ignoreFiscality={TAX_TYPE_LMNP} isLoading={isLoading} />
  );
}

import React, { useContext } from 'react';
import type { ComponentProps } from 'react';

import { usePanels } from 'hooks/usePanels';

import type { LotJson, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

import { LotDetailLayoutMobile } from 'commonUi/ListLotV2/LotDetailLayout/LotDetailLayoutMobile';
import { LotButtons } from 'commonUi/ListLotV2/LotButtons/LotButtons';
import LotDetailsInformations from 'commonUi/ListLotV2/LotDetails/LotDetailsInformations';
import LotDetailsImage from 'commonUi/ListLotV2/LotDetails/LotDetailsImage';
import LotPromotion from 'commonUi/ListLotV2/LotPromotion/LotPromotion';
import TagCommanderComponent from 'modules/App/TagCommander/TagCommanderComponent';
import FolderLinks from 'modules/Folders/FolderLinks';

interface LotDetailsMobileCpnProps {
  fiscalities: TaxTaxonomyTerm[];
  lot: LotJson;
  myOptions: ComponentProps<typeof LotButtons>['myOptions'];
  myBookings: ComponentProps<typeof LotButtons>['myBookings'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  statuses: ComponentProps<typeof LotButtons>['statuses'];
  updateStatus: ComponentProps<typeof LotButtons>['updateStatus'];
  lotApiOffre: LotTypeV2;
  programDatas: ProgramTypeV2;
}

export function LotDetailsMobileCpn({
  closeDrawer,
  fiscalities,
  lot,
  myOptions,
  myBookings,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  statuses = {},
  updateStatus = undefined,
  lotApiOffre,
  programDatas,
}: LotDetailsMobileCpnProps & Pick<ComponentProps<typeof LotDetailLayoutMobile>, 'closeDrawer'>) {
  const { setComponentPageVars } = useContext(TmsContext);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  return (
    <>
      <LotDetailLayoutMobile
        lotNumber={lot.number}
        closeDrawer={() => {
          setComponentPageVars({});
          closeDrawer?.();
        }}
        loaded={!!lotApiOffre && !!programDatas}
        renderButton={() => (
          <LotButtons
            program={programDatas}
            myBookings={myBookings}
            myOptions={myOptions}
            lot={lot}
            lotFromApi={lotApiOffre}
            statuses={statuses}
            updateStatus={updateStatus}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            openPanel={openPanel}
          />
        )}
        renderDetails={() => (
          <LotDetailsInformations
            lotFromApi={lotApiOffre}
            programFromApi={programDatas}
            fiscalities={fiscalities}
          />
        )}
        isImage={Boolean(programDatas?.perspectives?.panorama)}
        renderImage={() => (
          <LotDetailsImage lotFromApi={lotApiOffre} programFromApi={programDatas} isNoFno />
        )}
        renderPromotion={className => <LotPromotion className={className} lot={lotApiOffre} />}
      />

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      {pageTemplateRef && pageTemplate && (
        <TagCommanderComponent
          navigation_pagename={`${pageTemplateRef}.${lot.number}`}
          navigation_template={pageTemplate}
          useEffectDeps={['navigation_template', 'navigation_pagename']}
        />
      )}
    </>
  );
}

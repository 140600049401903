import { createContext } from 'react';
import type { KeyedMutator } from 'swr';

import type { PageTypes } from 'api/vi3pAPI/apiTypes/PageTypes';
import type { SWRVi3pDataType } from 'api/vi3pAPI/useSWRVi3p';

interface SettingsType {
  alertes_mail: {
    nb_resultats_recherche_lot: string;
    nb_resultats_recherche_programme: string;
  };
  email: {
    import_mail_signature: unknown;
  };
  pages: Record<PageTypes, string | null>;
  bloc_alerte?: {
    titre: string;
    image: string;
    contenu: string;
    bloc_alerte_cta: {
      libelle: string;
      url: string;
      target: '_blank' | '_self';
    };
    est_affiche: 0 | 1;
    date_modification: number;
  };
  home: {
    block_media: {
      media: {
        type: 'video' | 'image';
        url: string;
      } | null;
      media_preview: string | null;
      texte: string;
      liste_avec_pictogrammes: {
        texte: string;
        pictogramme: string;
      }[];
    };
    image: string[];
    speed?: number;
    sous_titre: string;
    titre: string;
  };
  footer?: {
    blocs: {
      collapse_mobile?: boolean;
      contenu?: string;
      image?: string;
      titre?: string;
    }[];
    copyright?: string;
  };
  blocks: {
    adb: string | null;
    adb_lame_service: string | null;
    equipped_kitchen: string | null;
  };
  promotions: {
    promotions_background_color: string;
    promotions_text_color: string;
  };
  cdo?: {
    liste_attribution?: 0 | 1;
    activite_commerciale_api?: 0 | 1;
  };
  map?: {
    angular_width_city?: string;
    angular_height_city?: string;
    angular_width_dpt?: string;
    angular_height_dpt?: string;
  };
  equipped_kitchen_label?: string;
  equipped_kitchen_url?: string;
  commercial_readonly: 0 | 1;
}

export default createContext<{
  settings: SettingsType;
  isLoading: boolean;
  mutate: () => void;
}>({
  settings: {
    alertes_mail: {
      nb_resultats_recherche_lot: '0',
      nb_resultats_recherche_programme: '0',
    },
    email: {
      import_mail_signature: undefined,
    },
    pages: {
      page_adb: null,
      cuisine_equipee: null,
      page_seo: null,
    },
    bloc_alerte: undefined,
    home: {
      block_media: {
        liste_avec_pictogrammes: [],
        media: null,
        media_preview: null,
        texte: '',
      },
      image: [],
      sous_titre: '',
      titre: '',
    },
    footer: undefined,
    blocks: {
      adb: null,
      adb_lame_service: null,
      equipped_kitchen: null,
    },
    promotions: {
      promotions_background_color: '',
      promotions_text_color: '',
    },
    cdo: {
      liste_attribution: 0 as const,
      activite_commerciale_api: 0 as const,
    },
    equipped_kitchen_label: undefined,
    equipped_kitchen_url: undefined,
    commercial_readonly: 0,
  },
  isLoading: false,
  mutate: (() => {}) as KeyedMutator<SWRVi3pDataType<SettingsType>>,
});

import React, { useState } from 'react';
import type { ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import { LABEL_LOT_DETAIL } from 'settings/index';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './LotDetailLayout.module.scss';

interface LotDetailLayoutTabletteProps {
  closeDrawer?: () => void;
  loaded: boolean;
  isImage?: boolean;
  lotNumber: string | undefined;
  renderDetails?: () => ReactNode;
  renderImage?: () => ReactNode;
  renderButton?: () => ReactNode;
  renderPromotion?: (className?: string) => ReactNode;
}

export function LotDetailLayoutMobile({
  closeDrawer,
  loaded,
  isImage = false,
  lotNumber,
  renderButton,
  renderDetails,
  renderImage,
  renderPromotion,
}: LotDetailLayoutTabletteProps) {
  const [openMore, setOpenMore] = useState(false);

  if (!loaded) {
    return (
      <div className={styles.loading}>
        <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={24} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button className={styles.returnBtn} onClick={closeDrawer} type="button">
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
        </button>
        <div className={styles.name}>
          {LABEL_LOT_DETAIL} {lotNumber}
          {renderButton && (
            <button
              className={styles.moreBtn}
              type="button"
              onClick={() => setOpenMore(prevState => !prevState)}
            >
              <Icon className={styles.moreBtnIcon} icon="more" />
            </button>
          )}
        </div>
      </div>
      {renderImage && (
        <div className={classnames(styles.image, { [styles.empty]: !isImage })}>
          {renderImage()}
        </div>
      )}
      {renderPromotion && renderPromotion(styles.promo)}
      {renderButton && openMore && <div className={styles.buttons}>{renderButton()}</div>}
      {renderDetails && renderDetails()}
    </div>
  );
}

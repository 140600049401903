import React, { useState } from 'react';
import classnames from 'classnames';

import { LABEL_NEW_RESIDENCE, LABEL_OPEN_SLIDESHOW, LABEL_VI4YOU } from 'settings/labels';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { lotIsControlledPrice, lotIsFNO, lotIsFurnishEligible, lotIsHonoboost } from 'services';
import { programIsNew, programIsVi4You } from 'services/programs';

import ProgramModalGallery from 'commonUi/ProgramModalGallery/ProgramModalGallery';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './LotDetailsImage.module.scss';

const IMG_VI4YOU = '/images/Logo_4you.png';

interface LotDetailsProps {
  lotFromApi?: LotTypeV2;
  programFromApi?: ProgramTypeV2;
  isNoFno?: boolean;
}

export default function LotDetailsImage({
  lotFromApi,
  programFromApi,
  isNoFno = false,
}: LotDetailsProps) {
  const [openGallery, setOpenGallery] = useState(false);

  if (!programFromApi || !lotFromApi) return null;

  const noImage = !programFromApi?.perspectives?.panorama;
  return (
    <div
      className={classnames(styles.root, {
        [styles.noImage]: noImage,
      })}
    >
      {programFromApi?.perspectives?.panorama && (
        <div className={styles.image}>
          <img className={styles.imageMain} src={programFromApi.perspectives.panorama} alt="" />
        </div>
      )}
      {programFromApi?.perspectives?.slider?.length > 0 && (
        <>
          <button className={styles.expand} type="button" onClick={() => setOpenGallery(true)}>
            {LABEL_OPEN_SLIDESHOW}
          </button>
          <ProgramModalGallery
            closeCallBack={() => setOpenGallery(false)}
            open={openGallery}
            program={programFromApi}
          />
        </>
      )}
      {programIsNew(programFromApi) && <div className={styles.new}>{LABEL_NEW_RESIDENCE}</div>}
      <div
        className={classnames(styles.infosPictos, {
          [styles.alone]: noImage,
        })}
      >
        {programIsVi4You(programFromApi) && (
          <div className={styles.infosPictosItem}>
            <img
              className={styles.vi4youImg}
              src={IMG_VI4YOU}
              title={LABEL_VI4YOU}
              alt={LABEL_VI4YOU}
            />
          </div>
        )}
        {lotIsControlledPrice(lotFromApi) && (
          <div className={styles.infosPictosItem}>
            <SvgIcon iconId="icon-prix-maitrises" />
          </div>
        )}
        {lotIsFNO(lotFromApi) && !isNoFno && (
          <div className={styles.infosPictosItem}>
            <SvgIcon iconId="icon-fno" />
          </div>
        )}
        {lotIsHonoboost(lotFromApi) && (
          <div className={styles.infosPictosItem}>
            <SvgIcon iconId="icon-honoboost" />
          </div>
        )}
        {lotIsFurnishEligible(lotFromApi) && (
          <div className={styles.infosPictosItem}>
            <SvgIcon iconId="icon-furnish-eligible" />
          </div>
        )}
      </div>
    </div>
  );
}

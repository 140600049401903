import React from 'react';

import type {
  Activity,
  ActivityHeading,
  ActivitySortByType,
  ActivitySortOrderType,
} from 'api/vi3pAPI/apiTypes/ActivityType';
import type { ActivityChoices } from 'api/viCrmApi/apiTypes/ActivityChoices';

import {
  ACTIVITY_CELL_TYPE_ACTIONS,
  ACTIVITY_CELL_TYPE_DATE,
  ACTIVITY_CELL_TYPE_DATE_TIME,
  ACTIVITY_CELL_TYPE_DEFAULT,
  ACTIVITY_CELL_TYPE_LOT,
  ACTIVITY_CELL_TYPE_NAME,
  ACTIVITY_CELL_TYPE_PRICE,
  ACTIVITY_CELL_TYPE_PROGRAM,
  ACTIVITY_CELL_TYPE_STATUS,
  ACTIVITY_CELL_TYPE_TAX,
  ACTIVITY_HEADING_ACT,
  ACTIVITY_HEADING_ACTIONS,
  ACTIVITY_HEADING_BOOKING,
  ACTIVITY_HEADING_CLIENT,
  ACTIVITY_HEADING_CONTRACT,
  ACTIVITY_HEADING_COUNSELOR,
  ACTIVITY_HEADING_END,
  ACTIVITY_HEADING_FEES,
  ACTIVITY_HEADING_LOT,
  ACTIVITY_HEADING_MANAGER,
  ACTIVITY_HEADING_PRICE,
  ACTIVITY_HEADING_PROGRAM,
  ACTIVITY_HEADING_SALE,
  ACTIVITY_HEADING_STATUS,
  ACTIVITY_HEADING_STATUS_SALES,
  ACTIVITY_HEADING_TAX,
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
  ACTIVITY_STATUS_ORDER as STATUS_ORDER,
} from 'settings/activity';
import {
  KITCHEN_TYPE_INLUDED,
  KITCHEN_TYPE_NONE,
  KITCHEN_TYPE_PAYING_OPTION,
} from 'settings/kitchen';
import {
  LABEL_KITCHEN_OPTION,
  LABEL_KITCHEN_OPTION_SUBTEXT,
  LABEL_SALES_AID_KITCHEN,
  LABEL_SALES_AID_NOTARY,
} from 'settings/labels';
import { SORT_ORDER_ASC } from 'settings/search';
import { TOKEN_PRICE } from 'settings/token';
import {
  USER_ROLE_DO,
  USER_ROLE_INDEP,
  USER_ROLE_OP,
  USER_ROLE_OTHER,
  USER_ROLE_SMC,
} from 'settings/user';

import type { UserRole } from 'api/vi3pAPI/apiTypes/UserTokenType';

import { normalizeTax } from './taxes';
import { formatPrice, replaceTokens } from './formatter';

export function getCommercialApiBaseUrl(commercialSwitch: boolean) {
  return commercialSwitch ? 'commercial/v3' : 'commercial/v2';
}

const ACTIVITY_HEADINGS: Record<string, ActivityHeading> = {
  [ACTIVITY_HEADING_CLIENT]: {
    field: ['field_prospectprenom_ac', 'field_prospectnom_ac'],
    title: 'Client',
    id: ACTIVITY_HEADING_CLIENT,
    sort: ACTIVITY_HEADING_CLIENT,
    type: ACTIVITY_CELL_TYPE_NAME,
    weight: {
      [ACTIVITY_TYPE_OPTION]: 18,
      [ACTIVITY_TYPE_BOOKING]: 18,
      [ACTIVITY_TYPE_PRE_BOOKING]: 15,
      [ACTIVITY_TYPE_SALE]: 13,
    },
  },
  [ACTIVITY_HEADING_COUNSELOR]: {
    field: ['field_prenomconseiller_ac', 'field_nomconseiller_ac'],
    title: 'Conseiller',
    id: ACTIVITY_HEADING_COUNSELOR,
    sort: ACTIVITY_HEADING_COUNSELOR,
    type: ACTIVITY_CELL_TYPE_NAME,
    weight: {
      [ACTIVITY_TYPE_OPTION]: 18,
      [ACTIVITY_TYPE_BOOKING]: 18,
      [ACTIVITY_TYPE_PRE_BOOKING]: 15,
      [ACTIVITY_TYPE_SALE]: 13,
    },
  },
  [ACTIVITY_HEADING_MANAGER]: {
    field: ['field_prenomresponsable_ac', 'field_nomresponsable_ac'],
    title: 'Responsable',
    id: ACTIVITY_HEADING_MANAGER,
    sort: ACTIVITY_HEADING_MANAGER,
    type: ACTIVITY_CELL_TYPE_NAME,
    weight: {
      [ACTIVITY_TYPE_OPTION]: 18,
      [ACTIVITY_TYPE_BOOKING]: 18,
      [ACTIVITY_TYPE_PRE_BOOKING]: 15,
      [ACTIVITY_TYPE_SALE]: 13,
    },
  },
  [ACTIVITY_HEADING_TAX]: {
    field: 'field_dispositiffiscal_ac',
    title: 'Fiscalité',
    id: ACTIVITY_HEADING_TAX,
    sort: ACTIVITY_HEADING_TAX,
    type: ACTIVITY_CELL_TYPE_TAX,
    weight: 9,
  },
  [ACTIVITY_HEADING_PROGRAM]: {
    title: 'Programme',
    id: ACTIVITY_HEADING_PROGRAM,
    sort: ACTIVITY_HEADING_PROGRAM,
    type: ACTIVITY_CELL_TYPE_PROGRAM,
    weight: 18,
  },
  [ACTIVITY_HEADING_LOT]: {
    title: 'Lot',
    id: ACTIVITY_HEADING_LOT,
    sort: ACTIVITY_HEADING_LOT,
    type: ACTIVITY_CELL_TYPE_LOT,
    weight: 7,
  },
  [ACTIVITY_HEADING_PRICE]: {
    field: 'field_prixlotttc_ac',
    title: 'Prix TTC',
    id: ACTIVITY_HEADING_PRICE,
    sort: ACTIVITY_HEADING_PRICE,
    type: ACTIVITY_CELL_TYPE_PRICE,
    unit: '€',
    weight: 12,
  },
  [ACTIVITY_HEADING_SALE]: {
    field: 'field_montantvente_ac',
    title: 'Montant de la vente',
    id: ACTIVITY_HEADING_SALE,
    sort: ACTIVITY_HEADING_SALE,
    type: ACTIVITY_CELL_TYPE_PRICE,
    unit: '€',
    weight: 18,
  },
  [ACTIVITY_HEADING_FEES]: {
    field: 'field_tauxhonorairesfinal_ac',
    title: 'Honoraires',
    id: ACTIVITY_HEADING_FEES,
    sort: ACTIVITY_HEADING_FEES,
    type: ACTIVITY_CELL_TYPE_DEFAULT,
    unit: ' %',
    weight: 10,
  },
  [ACTIVITY_HEADING_ACT]: {
    field: 'field_dateacte_ac',
    title: 'Acte',
    id: ACTIVITY_HEADING_ACT,
    sort: ACTIVITY_HEADING_ACT,
    type: ACTIVITY_CELL_TYPE_DATE,
    weight: 10,
  },
  [ACTIVITY_HEADING_BOOKING]: {
    field: 'field_datereservation_ac',
    title: 'Réservation',
    id: ACTIVITY_HEADING_BOOKING,
    sort: ACTIVITY_HEADING_BOOKING,
    type: ACTIVITY_CELL_TYPE_DATE,
    weight: 10,
  },
  [ACTIVITY_HEADING_END]: {
    field: 'field_dateexpiration_ac',
    title: "Fin de l'option",
    id: ACTIVITY_HEADING_END,
    sort: ACTIVITY_HEADING_END,
    type: ACTIVITY_CELL_TYPE_DATE_TIME,
    weight: 8,
  },
  [ACTIVITY_HEADING_STATUS]: {
    field: 'field_statutnom_ac',
    title: 'Statut',
    id: ACTIVITY_HEADING_STATUS,
    sort: ACTIVITY_HEADING_STATUS,
    type: ACTIVITY_CELL_TYPE_STATUS,
    weight: 13,
  },
  [ACTIVITY_HEADING_STATUS_SALES]: {
    field: 'field_statutnom_ac',
    title: 'Statut',
    id: ACTIVITY_HEADING_STATUS,
    sort: ACTIVITY_HEADING_STATUS,
    type: ACTIVITY_CELL_TYPE_STATUS,
    weight: 10,
  },
  [ACTIVITY_HEADING_ACTIONS]: {
    title: '',
    id: ACTIVITY_HEADING_ACTIONS,
    type: ACTIVITY_CELL_TYPE_ACTIONS,
    separator: 'none',
    weight: 17,
  },
  [ACTIVITY_HEADING_CONTRACT]: {
    title: 'Contrat',
    id: ACTIVITY_HEADING_CONTRACT,
    type: ACTIVITY_CELL_TYPE_ACTIONS,
    separator: 'none',
    weight: {
      [ACTIVITY_TYPE_BOOKING]: 10,
      [ACTIVITY_TYPE_PRE_BOOKING]: 22,
      [ACTIVITY_TYPE_SALE]: 10,
    },
  },
};
const ACTIVITY_HEADINGS_ROLE_MAPPING_BY_TYPE: Record<
  | typeof ACTIVITY_TYPE_OPTION
  | typeof ACTIVITY_TYPE_PRE_BOOKING
  | typeof ACTIVITY_TYPE_BOOKING
  | typeof ACTIVITY_TYPE_SALE,
  Partial<
    Record<
      | typeof USER_ROLE_OP
      | typeof USER_ROLE_DO
      | typeof USER_ROLE_SMC
      | typeof USER_ROLE_INDEP
      | typeof USER_ROLE_OTHER,
      string[]
    >
  >
> = {
  [ACTIVITY_TYPE_BOOKING]: {
    [USER_ROLE_OP]: [
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_DO]: [
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_SMC]: [
      ACTIVITY_HEADING_MANAGER,
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_INDEP]: [
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
  },
  [ACTIVITY_TYPE_OPTION]: {
    [USER_ROLE_OP]: [
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_ACTIONS,
    ],
    [USER_ROLE_DO]: [
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_ACTIONS,
    ],
    [USER_ROLE_SMC]: [
      ACTIVITY_HEADING_MANAGER,
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_ACTIONS,
    ],
    [USER_ROLE_INDEP]: [
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_ACTIONS,
    ],
    [USER_ROLE_OTHER]: undefined,
  },
  [ACTIVITY_TYPE_PRE_BOOKING]: {
    [USER_ROLE_OP]: [
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_DO]: [
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_SMC]: [
      ACTIVITY_HEADING_MANAGER,
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_INDEP]: [
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_STATUS,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_OTHER]: undefined,
  },
  [ACTIVITY_TYPE_SALE]: {
    [USER_ROLE_OP]: [
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_BOOKING,
      ACTIVITY_HEADING_ACT,
      ACTIVITY_HEADING_STATUS_SALES,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_DO]: [
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_BOOKING,
      ACTIVITY_HEADING_ACT,
      ACTIVITY_HEADING_STATUS_SALES,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_SMC]: [
      ACTIVITY_HEADING_MANAGER,
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_BOOKING,
      ACTIVITY_HEADING_ACT,
      ACTIVITY_HEADING_STATUS_SALES,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_INDEP]: [
      ACTIVITY_HEADING_COUNSELOR,
      ACTIVITY_HEADING_CLIENT,
      ACTIVITY_HEADING_PROGRAM,
      ACTIVITY_HEADING_LOT,
      ACTIVITY_HEADING_PRICE,
      ACTIVITY_HEADING_SALE,
      ACTIVITY_HEADING_FEES,
      ACTIVITY_HEADING_TAX,
      ACTIVITY_HEADING_BOOKING,
      ACTIVITY_HEADING_ACT,
      ACTIVITY_HEADING_STATUS_SALES,
      ACTIVITY_HEADING_CONTRACT,
    ],
    [USER_ROLE_OTHER]: undefined,
  },
};
export function getActivitiesHeadings(
  activityType: keyof typeof ACTIVITY_HEADINGS_ROLE_MAPPING_BY_TYPE,
  userType: UserRole | undefined
) {
  const headingIds = userType
    ? ACTIVITY_HEADINGS_ROLE_MAPPING_BY_TYPE[activityType][userType]
    : undefined;
  return headingIds ? headingIds.map(headingId => ACTIVITY_HEADINGS[headingId]).filter(x => x) : [];
}

export function sortActivities(
  array: Activity[] = [],
  sortBy: ActivitySortByType,
  sortOrder: ActivitySortOrderType
) {
  return array
    .filter(x => x !== undefined)
    .sort((a, b) => {
      const order = Number(sortOrder === SORT_ORDER_ASC) * 2 - 1;
      if (sortBy === ACTIVITY_HEADING_STATUS) {
        const statusA = a[ACTIVITY_HEADING_STATUS];
        const dateA = a.field_datereservation_ac;
        const statusB = b[ACTIVITY_HEADING_STATUS];
        const dateB = b.field_datereservation_ac;
        /* Depending on Sort Order, the primary order (the status) should be inverted but the
         * secondary order (the booking date) should still be descending (to show newer first) */
        if (statusA === statusB) {
          if (!dateA) {
            return 1;
          }
          if (!dateB) {
            return -1;
          }
          return new Date(dateB).getTime() - new Date(dateA).getTime();
        }
        return order * (STATUS_ORDER.indexOf(statusA) - STATUS_ORDER.indexOf(statusB));
      }
      const [value1, value2] = [a, b].map(activity => {
        switch (sortBy) {
          // Sort value as a number
          case ACTIVITY_HEADING_PRICE:
            return parseFloat(activity.field_prixlotttc_ac.replace(/\s/g, ''));
          case ACTIVITY_HEADING_SALE:
            return parseFloat(activity.field_montantvente_ac.replace(/\s/g, ''));
          case ACTIVITY_HEADING_FEES:
            return parseFloat(activity.field_tauxhonorairesfinal_ac.replace(/\s/g, ''));

          // Sort value as a Date
          case ACTIVITY_HEADING_END:
            return (
              (activity.field_dateexpiration_ac
                ? Date.parse(activity.field_dateexpiration_ac)
                : undefined) || 0
            );
          case ACTIVITY_HEADING_BOOKING:
            return (
              (activity.field_datereservation_ac
                ? Date.parse(activity.field_datereservation_ac)
                : undefined) || 0
            );
          case ACTIVITY_HEADING_ACT:
            return (
              (activity.field_dateacte_ac ? Date.parse(activity.field_dateacte_ac) : undefined) || 0
            );

          // Sort value as is (most likely a string)
          case ACTIVITY_HEADING_CLIENT:
            return [activity.field_prospectprenom_ac, activity.field_prospectnom_ac].join(' ');
          case ACTIVITY_HEADING_COUNSELOR:
            return [activity.field_prenomconseiller_ac, activity.field_nomconseiller_ac].join(' ');
          case ACTIVITY_HEADING_MANAGER:
            return [activity.field_prenomresponsable_ac, activity.field_nomresponsable_ac].join(
              ' '
            );

          // Sort lot numbers as strings
          case ACTIVITY_HEADING_LOT:
            return activity.field_numerolot_ac;

          // Sort program names as strings
          case ACTIVITY_HEADING_PROGRAM:
            return activity.field_nomprogramme_ac;

          // Sort tax types as strings
          case ACTIVITY_HEADING_TAX:
            return normalizeTax(activity.field_dispositiffiscal_ac);

          default:
        }
        const exhaustivenessCheck: never = sortBy;
        return exhaustivenessCheck;
      });

      if (value1 === value2) {
        return 0;
      }
      if (value1 === undefined) {
        return order;
      }
      if (value2 === undefined) {
        return -1 * order;
      }
      return order * (Number(value1 > value2) * 2 - 1);
    });
}

/*
 * CAREFUL: THE STEPS ARE 0-indexed
 * Step 1: Pre-book Lot
 * Step 2: Generate contract
 * Step 3: Send signature request to client
 * Step 4: Client has signed contract
 * Step 5: VINCI signed contract
 */
export function getActivityStep(activity: Activity): number {
  const hasContract = Boolean(activity.field_dategenerationcontrat_ac);
  const isSent = Boolean(activity.field_datedernierenvoi_ac);
  const hasVinciSigned = Boolean(activity.field_datecontratsigne_vinci_ac);

  if (activity.field_type_ac === ACTIVITY_TYPE_BOOKING) {
    if (activity.field_isclientcontratsigned && hasVinciSigned) {
      return 5;
    }
    if (activity.field_isclientcontratsigned) {
      return 4;
    }
  }

  if (activity.field_type_ac === ACTIVITY_TYPE_PRE_BOOKING) {
    if (hasContract && isSent && activity.field_isclientcontratsigned && hasVinciSigned) {
      return 5;
    }

    if (hasContract && isSent && activity.field_isclientcontratsigned) {
      return 4;
    }

    if (hasContract && isSent) {
      return 3;
    }

    if (hasContract) {
      return 2;
    }

    return 1;
  }

  return 0;
}

const ACTIVITY_STEPS_CHECKER: ((activity: Activity) => boolean)[] = [
  activity => Boolean(activity.field_datecontratsigne_vinci_ac),
  activity => Boolean(activity.field_dateenvoi_courrier_sru_ac),
  activity => activity.depot_garantie.receptionPaiement,
  activity => activity.field_financement_credit || activity.field_financement_cash,
  activity =>
    (activity.field_financement_credit && Boolean(activity.field_dateoffre_pret_ac)) ||
    activity.field_financement_cash,
  activity => Boolean(activity.field_dateacte_reel_ac),
];
export function getBookingSaleActivityStep(activity: Activity) {
  // Find first step index that is NOT validated. If they're all validated, then we're in the last step
  const index = ACTIVITY_STEPS_CHECKER.findIndex(callback => !callback(activity));
  return index === -1 ? ACTIVITY_STEPS_CHECKER.length : index;
}

type MandatoryFields =
  | 'field_idcrm_ac'
  | 'field_numerolot_ac'
  | 'field_referenceprogramme_ac'
  | 'field_dispositiffiscal_ac'
  | 'field_statutnom_ac'
  | 'field_type_ac';
export function getDefaultActivity(
  fieldsToUpdate: Pick<Activity, MandatoryFields> & Partial<Omit<Activity, MandatoryFields>>
): Activity {
  return {
    field_nomprogramme_ac: '',
    field_tauxhonorairesfinal_ac: '',
    field_dateexpiration_ac: '',
    field_dateacte_ac: '',
    field_datereservation_ac: '',
    field_datedernierenvoi_ac: '',
    field_dategenerationcontrat_ac: '',
    field_idprogramme_ac: '',
    field_idcompte_ac: '',
    field_statut_ac: '',
    field_tva: '',
    field_financement_ht: '0',
    field_financement_credit: false,
    field_apport_personnel_credit: '',
    field_montant_credit: '',
    field_cuisine_equipee_prix: '',
    field_cuisine_equipee_type: KITCHEN_TYPE_NONE,
    field_datecontratsigne_client_ac: '',
    field_datecontratsigne_vinci_ac: '',
    field_prixlotinitialttc_ac: 0,
    field_prixlotttc_ac: '',
    field_montantvente_ac: '',
    field_prenomconseiller_ac: '',
    field_nomconseiller_ac: '',
    field_prenomresponsable_ac: '',
    field_nomresponsable_ac: '',
    field_prospectprenom_ac: '',
    field_prospectnom_ac: '',
    field_prospectdatenaissance_ac: '',
    field_prospectlieunaissance_ac: '',
    field_prospectnationalite_ac: '',
    field_prospectadresse_ac: '',
    field_prospectcodepostal_ac: '',
    field_prospectville_ac: '',
    field_prospectpays_ac: '',
    field_prospectcivilite_ac: '',
    field_prospectmail_ac: '',
    field_prospecttelephone_ac: '',
    field_prospectsituationactuelle_ac: '',
    field_prospectdestachat_ac: '',
    field_prospectstatutfamilial_ac: '',
    field_coprospectprenom_ac: '',
    field_coprospectnom_ac: '',
    field_coprospectdatenaissance_ac: '',
    field_coprospectlieunaissance_ac: '',
    field_coprospectnationalite_ac: '',
    field_coprospectadresse_ac: '',
    field_coprospectcodepostal_ac: '',
    field_coprospectville_ac: '',
    field_coprospectpays_ac: '',
    field_coprospectcivilite_ac: '',
    field_coprospectmail_ac: '',
    field_coprospecttelephone_ac: '',
    field_coprospectsituationactuelle_ac: '',
    field_coprospectdestachat_ac: '',
    field_coprospectstatutfamilial_ac: '',
    field_rentabilite_pinel: '',
    field_rentabilite_marche: '',
    field_frais_notaire_offerts: false,
    field_documents: {},
    field_documents_ac: [],
    ...fieldsToUpdate,
    depot_garantie: {
      mode_paiement: '',
      remisecheque: '',
      montant: '',
      banque: '',
      iban: '',
      bic: '',
      rib: '',
      receptionPaiement: false,
      ...fieldsToUpdate.depot_garantie,
    },
    field_isclientcontratsigned: false,
    field_ptz: false,
    field_financement_cash: false,
    field_dateenvoi_courrier_sru_ac: '',
    field_dateoffre_pret_ac: '',
    field_dateacte_reel_ac: '',
  };
}

export function getActivityActions(activity: Activity, eligibility: boolean, readonly?: any) {
  const showActions = {
    showCancelOption: false,
    showCancelPreBook: false,
    showDownloadContract: false,
    showEditContract: false,
    showInfo: false,
    showMakeContract: false,
    showMakeOption: false,
    showMakePrebook: false,
    showSendSignRequest: false,
    isSignRequestReadonly: false,
  };

  if (readonly) {
    return showActions;
  }

  const canMakeContract = window.vinci.REACT_APP_ENABLE_DIGITAL_SIGNING === 'true' && eligibility;
  const hasContracts =
    (activity.field_documents && Object.keys(activity.field_documents).length > 0) ||
    activity.field_documents_ac.length > 0;

  switch (activity.field_type_ac) {
    case ACTIVITY_TYPE_OPTION:
      return {
        ...showActions,
        showCancelOption: activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE,
        showMakeOption: activity.field_statutnom_ac === ACTIVITY_STATUS_LOST,
        showMakePrebook: [ACTIVITY_STATUS_ACTIVE, ACTIVITY_STATUS_LOST].includes(
          activity.field_statutnom_ac
        ),
      };

    case ACTIVITY_TYPE_PRE_BOOKING:
      return {
        ...showActions,
        showCancelPreBook: activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE,
        showDownloadContract: hasContracts,
        showEditContract:
          canMakeContract && activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE && hasContracts,
        showInfo: true,
        showMakeContract:
          canMakeContract &&
          activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE &&
          !hasContracts,
        showSendSignRequest: activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE && hasContracts,
        isSignRequestReadonly: activity.field_statutnom_ac === ACTIVITY_STATUS_LOST,
      };

    case ACTIVITY_TYPE_BOOKING:
    case ACTIVITY_TYPE_SALE:
      return {
        ...showActions,
        showDownloadContract: hasContracts,
        showInfo: true,
      };

    default:
  }

  const exhaustivenessCheck: never = activity.field_type_ac;
  return exhaustivenessCheck;
}

export function getActivityChoicesOptions(
  activity: Activity
): {
  id: string;
  content: JSX.Element | ((classes: Record<string, string | undefined>) => JSX.Element);
}[] {
  if (activity.field_cuisine_equipee_type === KITCHEN_TYPE_PAYING_OPTION) {
    return [
      {
        id: 'kitchen',
        content: ({ footnote: footnoteClassName } = { footnote: undefined }) => (
          <>
            {replaceTokens(LABEL_KITCHEN_OPTION, {
              [TOKEN_PRICE]: formatPrice(activity.field_cuisine_equipee_prix, '€', true),
            })}
            <span className={footnoteClassName}>{LABEL_KITCHEN_OPTION_SUBTEXT}</span>
          </>
        ),
      },
    ];
  }
  return [];
}

export function getActivityChoicesSalesAids(choices: ActivityChoices): string[];
export function getActivityChoicesSalesAids(activity: Activity): string[];
export function getActivityChoicesSalesAids(activityOrChoices: Activity | ActivityChoices) {
  let hasFreeNotary: boolean;
  let hasKitchen: boolean;
  const salesAids: string[] = [];

  function isActivity(
    activityOrChoices: Activity | ActivityChoices
  ): activityOrChoices is Activity {
    return !!(activityOrChoices as Activity).field_idcrm_ac;
  }

  if (isActivity(activityOrChoices)) {
    hasFreeNotary = activityOrChoices.field_frais_notaire_offerts;
    hasKitchen = activityOrChoices.field_cuisine_equipee_type === KITCHEN_TYPE_INLUDED;
  } else {
    hasFreeNotary = activityOrChoices.aideVentes.fraisNotaireOfferts;
    hasKitchen =
      activityOrChoices.packs.maCuisineEquipee.type === KITCHEN_TYPE_INLUDED &&
      activityOrChoices.packs.maCuisineEquipee.eligible;
  }

  if (hasFreeNotary) {
    salesAids.push(LABEL_SALES_AID_NOTARY);
  }
  if (hasKitchen) {
    salesAids.push(LABEL_SALES_AID_KITCHEN);
  }
  return salesAids;
}
